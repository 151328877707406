<template>
  <div>
    <nav class="bg">
    <img src="../assets/logo.png" class="logo">
    </nav>
    <!-- Voto enviado -->
    <div v-if="confirmado" class="center">
      <h1>Votación terminada!</h1>
      <p>Gracias por participar.</p>
    </div>
    <!-- Confirmar Candidatos -->
    <div v-if="votados && !confirmado" class="center">
      <h1>Confirmar Selección:</h1> <br>
      <div v-for="votado in votados" :key="votado.id">
        <p>{{votado.nombre}} {{votado.apellido}}</p>
      </div>
      <input type="submit" value="Volver" @click="volver()">
      <input type="submit" value="Confirmar" @click="guardar()">
    </div>
    <!-- Elegir Candidatos -->
    <div>      
      <v-container v-if="candidatos && !terminado" fluid grid-list-sm>
        <div class="center" v-if="config"> 
          <h1>Instrucciones:</h1>
          <br> 
          <p>{{config.inst1}}</p>
        </div>
        <br>
        <h2 class="text-xs-center">Votos Disponibles: {{votosDisponibles}}</h2>
        <p></p>
        <p class="text-xs-center"><v-btn color="blue darken-1" type="submit" @click="logout()">Salir</v-btn><input type="submit" @click="terminar()" value="Continuar"></p>
        <v-alert :value="alert" type="error" transition="scale-transition">Aun tienes votos disponibles.</v-alert>
        <v-layout row wrap>
          <v-flex v-for="candidato in candidatos" @click="votar(candidato)" :key="candidato.id" xs4>
            <div :class="'candidato text-xs-center' + ((candidato.votado) ? ' votado' : '')">
              <p><img :src="candidato.img" class="candidato"></p>
              <p>Sr. {{candidato.nombre}} {{candidato.apellido}}</p>
              <p>{{candidato.desc}}</p>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <v-dialog persistent v-model="timeOut" max-width="600px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-layout>
              <v-flex class="center">
                <h1>Control de Sesi&oacute;n</h1>
                <h2>Hemos detectado inactividad ¿Necesitas mas tiempo?</h2>
                <h4></h4>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click="closeIt">Cerrar</v-btn>
          <v-btn color="blue darken-1" dark @click="keepIt">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isLogged" max-width="600px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-layout>
              <v-flex class="center">
                <h1>Control de Sesi&oacute;n</h1>
                <h2>Hemos detectado comportamiento extraño en el Sistema</h2>
                <h4>Por seguridad esta sesi&oacute;n será cerrada</h4><br>
                <v-btn color="blue darken-1" dark @click="logout">Cerrar</v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <footer></footer>
  </div>
</template>

<script>
  export default {
    name: 'votar',
    data: () =>({
      timeOut: false,
      config: undefined,
      candidatos: undefined,
      votados: undefined,
      terminado: 0,
      confirmado: 0,
      votosDisponibles: 7,
      alert: false,
      isLogged: false
    }),
    methods: {
      getConfig() {
        this.$http.get(this.$cfg.base + '/cfg').then(response => {
          this.config = response.body[0]
          if(this.config.active==true) {
            this.hasVoted()
            this.getCandidatos()
          } else {
            this.$router.push('unactive')
          }
        }
        , error => alert('Hubo un Error' + error))
      },
      getCandidatos() {
        this.$http.get(this.$cfg.base + '/candidatos').then(response => {
          if(!this.candidatos) this.candidatos = []
          response.body.forEach(candidato => {
            candidato.votado = 0
            this.candidatos.push(candidato)
          })
        }, error => alert('Hubo un Error' + error))
      },
      hasVoted() {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user.hasVoted === true) this.$router.push('fin')
        else if(user.votoFisico === true) this.$router.push('fin')
      },
      volver() {
        this.checkToken()
        this.votados = undefined
        this.terminado = 0
        this.candidatos.forEach(candidato => {
          candidato.votado = 0
        })
        this.votosDisponibles = 7
      },
      votar(candidato) {
        document.title = 'Elegir Candidatos | Sistema de Votacion Electronica CIRPAN A.G.'
        this.checkToken()
        if(candidato.votado === 1) {
          candidato.votado = 0
          this.votosDisponibles++
        } else {
          if(this.votosDisponibles > 0) {
            candidato.votado = 1
            this.votosDisponibles--
          }
        }
      },
      terminar() {
        document.title = 'Confirmar Seleccion | Sistema de Votacion Electronica CIRPAN A.G.'
        let votados = this.candidatos.filter(candidato => candidato.votado === 1)
        this.checkToken()
        if(votados.length < 7) {
          this.alert = true
        } else {
          this.votados = votados
          this.terminado = 1
          this.alert= false
        }
      },
      guardar() {
        document.title = 'Votacion Finalizada | Sistema de Votacion Electronica CIRPAN A.G.'
        this.checkToken()
        let data = {
          votados: [],
          id: this.$route.params.id
        }
        this.votados.forEach(votado => {
          if(votado.votado) {
            data.votados.push(votado._id)
          }
        })
        this.$http.post(this.$cfg.base + '/votar', data)
        this.confirmado = 1            
      },
      logout() {
        localStorage.removeItem('user')
        this.$session.remove('jwt')
        this.$router.push('/')
      },
      keepIt(){
        this.timeOut = false
      },
      closeIt(){
        this.timeOut = false
        this.logout()
      },
      checkToken(){
        // console.log(this.$session.get('jwt'))
        const user = JSON.parse(localStorage.getItem('user'))
        if(user){
          this.$http.get(`${this.$cfg.base}/user/${user._id}`).then( response => {
            if (user.token != response.body.token){
              this.isLogged = true
              setTimeout(() => {
                this.logout()
              }, 5000)
            }
          })
        }
      }
    },
    created() {
      this.getConfig()
      setTimeout(() => {
        this.timeOut = true
      }, 150000)
      setTimeout(() => {
        this.logout()
      }, 300000)
    },
    beforeUpdate() {
      this.checkToken()
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    }
  }
</script>

<style scoped>
body{
  text-align: center;
}
img.candidato{
  height: 150px;
  border-radius: 100%;
  box-shadow: rgba(0,0,0,.6) 0 2px 4px;
  padding: 0;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
img.logo{
  padding: 15px;
  width: 150px;
}

footer{
  height:240px;
  background-color: #EB6513;
  -webkit-clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}

.candidato {
  display:block;
  background: none;
  padding: 1em;
}
.votado {
  background-color: rgba(0, 255, 0, .5);
  border-radius: 10px;
  border: solid 1px green;
}
.center{
  text-align: center;
}
.flex{
  display: flex;
  flex-wrap: wrap;

  align-items: center;
  justify-content: space-around;
  align-content: space-around;
}
input[type=submit]{
  margin: 5px;
  font-size: 14px;
  padding:10px 18px;
  border-radius: 5px;
  color: black;
  background-color: royalblue;
}
</style>