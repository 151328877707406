<template>
  <div> 
    <Navbar/>  
    <!--Casos/>
    <br>
    <br>
    <Usuarios/-->
    <v-container class="">
      <h1>Administrador de Elecciones</h1>
    <router-view/>
    </v-container>
  </div>
</template>

<script>
  import Navbar from '@/components/admin/Navbar.vue'
  
  export default {
    name: 'admin',
    components: {
      Navbar
    },
    methods: {
      isAdmin() {
        let user = JSON.parse(localStorage.getItem('user'))
        if (user && user.role != 'Administrador') {
          this.$router.push('unactive')
        }
      },
    },
    beforeCreate() {
      document.title = 'Sistema Elecciones CIRPAN A.G.| Admin'
    },
    mounted() {
      this.isAdmin()
    }
  }
</script>

<style>
.v-list__tile__action .v-icon {
  cursor: pointer;
}
</style>