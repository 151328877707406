<template>
<div>
	<v-card>
		<v-card-title>
			<span class="headline">Configuraciones</span>
			<v-spacer></v-spacer>
			<v-btn color="orange darken-2" dark @click="save">Guardar</v-btn>
		</v-card-title>
		<v-card-text>
			<v-container grid-list-md>
				<v-layout wrap>
					<v-flex xs12 sm6 md4>
						<v-textarea v-model="config.textHome" label="Texto Inicio" :value="config.textHome"></v-textarea>
					</v-flex>
					<v-flex xs12 sm6 md4>
						<v-textarea v-model="config.inst1" label="Instrucciones" :value="config.inst1"></v-textarea>
					</v-flex>
					<v-flex xs12 sm6 md4>
						<v-textarea v-model="config.titulo1" label="Titulo Resultado" :value="config.titulo1"></v-textarea>
					</v-flex>
					<v-flex xs12 sm6 md4>
						<v-textarea v-model="config.texto1" label="Texto Resultado" :value="config.texto1"></v-textarea>
					</v-flex>
					<v-flex xs12 sm6 md4>
						<v-switch v-model="config.active" :label="translated"></v-switch>
					</v-flex>
					<v-flex xs12 sm6 md4>
						<v-btn color="grey darken-2" dark @click="resultado">Crear Resultado y Reiniciar</v-btn>
					</v-flex>
				</v-layout>
			</v-container>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="orange darken-2" dark @click="save">Guardar</v-btn>
		</v-card-actions>
	</v-card>
</div>
</template>

<script>
	export default {
		data: () => ({
			url: null,
			config: {}
		}),
		computed: {
			translated () {
				return this.formatBulean(this.config.active)
			}
		},
		watch: {
			dialog (val) {
			val || this.close()
			}
		},
		mounted () {
			this.get()
		},
		methods: {
			formatBulean (format){
				if(format === true){
					return "Sistema Activado"
				}else{
					return "Sistema Desactivado"
				}
			},
			get () {
				var url = this.$cfg.base + '/cfg'
				this.$http.get(url).then(
					response => {
						if(response.body.length > 0) this.config = response.body[0]
						else this.config = undefined
					},error => alert('Hubo un Error' + error)
				)
			},
			save () {
				var config = {
				textHome: this.config.textHome,
				inst1: this.config.inst1,
				titulo1: this.config.titulo1,
				texto1: this.config.texto1,
				active: this.config.active
				}
				this.$http.put(`${this.$cfg.base}/cfg/${this.config.id}`, config).then(
					() => {
						alert('Cambios Guardados')
						this.get()
					}, error => alert('Hubo un Error' + error)
				)
			},
			resultado() {
				if(this.config.active == false){
					alert('No se puede generar el Resultado. El Sistema esta desactivado')
				}else{
					let sure = confirm("Esta seguro que desea crear el resultado y reiniciar")
					if (sure === true){
						this.config.active = false
						this.save()
						alert('Sistema Desactivado. Enviando a Resultado')
						setTimeout(() => {
							this.$router.push('/resultado')
						}, 3000)
					}else{
						alert('No se creo el Resultado')
					}
				}
			}
		}
	}
</script>