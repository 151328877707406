import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import VueResource from 'vue-resource'
import Vuetify from 'vuetify'
import VueSession from 'vue-session'

var options = {
    persist: true
}
Vue.use(VueSession, options)
Vue.use(VueResource);
Vue.use(Vuetify);

// Globales
var cfg = {
  base: 'https://cirpan.cl:4000/api'
}
Vue.prototype.$cfg = cfg

Vue.config.productionTip = false
new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
