<template>
<div>
  <nav class="bg">
    <img class="logo" src="../assets/CIRPAN-nav.png" alt="CIRPAN">
  </nav>
  <div class="center">
  <h1>Usted ya ha Votado!</h1>
  <p>Gracias por participar.</p>
  <v-btn @click="logout" color="blue">Salir</v-btn>
  </div>
</div>
</template>

<script>
export default{
	name: 'fin',
	data(){
		return {
			
		}
	},
	methods:{
		logout() {
			localStorage.removeItem('user')
			this.$session.remove('jwt')
			this.$router.push('/')
		}
	},
	created(){
		setTimeout(() => {
			this.logout()
		}, 10000)
	}
}
</script>

<style scoped>
.center{
  text-align: center;
}
footer{
  height:240px;
  background-color: #EB6513;
  -webkit-clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}
</style>
