<template>
  <v-container>
    <v-toolbar flat color="white">
      <v-toolbar-title>Candidatos</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-dialog v-model="dialog" max-width="600px">
        <template v-slot:activator="{ on }">
          <v-btn color="orange darken-2" dark class="mb-2" v-on="on">Nuevo Candidato</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md4>
                  <v-text-field v-model="editedItem.nombre" label="Nombre"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field v-model="editedItem.apellido" label="Apellido"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field v-model="editedItem.desc" label="Descripción"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  
                  <img v-if="editedItem.img == undefined" src="https://via.placeholder.com/150/eb6513/000.png"/>
                  <img v-else :src="editedItem.img" alt="" width="100">
                  <br>
                  <v-icon color="green" v-if="editedItem.img != undefined">check_circle</v-icon>
                  <v-icon color="red" v-else>cancel</v-icon>
                  <input name="file" ref="fileInput" @change="uploadFile" type="file">
                </v-flex>

                <v-flex xs12 sm6 md4>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="orange darken-2" dark @click="close">Cancelar</v-btn>
            <v-btn color="orange darken-2" dark @click="save">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <v-card v-if="candidatos">
      <v-card-text>
        <v-list two-line>
          <template>
            <div v-for="(candidato, index) in candidatos" :key="candidato.id">
              <v-list-tile class="cands">
                <v-list-tile-avatar size=100>
                  <img :src="candidato.img"/>
                </v-list-tile-avatar>
                <v-list-tile-content class="title-1">
                  <v-list-tile-title>{{candidato.nombre}} {{candidato.apellido}}</v-list-tile-title>
                  <v-list-tile-sub-title>{{candidato.desc}}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action @click="editItem(candidato)">
                  <v-icon>edit</v-icon>
                </v-list-tile-action>
                <v-list-tile-action @click="deleteThis(candidato.id)">
                  <v-icon>delete</v-icon>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider v-if="index != candidatos.length - 1"/>
            </div>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

export default {
  data: () => ({
    dialog: false,
    selectedFile: null,
    url: null,
    headers: [
      { text: 'Nombre', value: 'nombre' },
      { text: 'Apellido', value: 'apellido' },
      { text: 'Descripción', value: 'desc' },
      { text: 'Imagen', value: 'img' },
      { text: 'Acciones', value: 'name', sortable: false }
    ],
    editedIndex: -1,
    editedItem: {
      nombre: undefined,
      apellido: undefined,
      desc: undefined,
      img: undefined
    },
    defaultItem: {
      nombre: undefined,
      apellido: undefined,
      desc: undefined,
      img: undefined
    },
    candidatos: []
  }),
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Candidato' : 'Editar Candidato'
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  mounted () {
    this.get()
  },
  methods: {
    get () {
      var url = this.$cfg.base + '/candidatos'
      this.$http.get(url).then(
        response => {
          if(response.body.length > 0) this.candidatos = response.body
          else this.candidatos = undefined
        }, 
        error => alert('Hubo un Error' + error)
      )
    },
    editItem (item) {
      this.editedIndex = this.candidatos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteThis(id){
      var sure = confirm("Esta seguro que desea eliminar el Candidato")
      if (sure === true){
        this.deleteItem(id)
        alert("Candidato Eliminado")
      }else{
        alert("No se elimino el Candidato")
      }
    },
    deleteItem (id) {
      this.$http.delete(`${this.$cfg.base}/candidato/${id}`).then(
        () =>{
          this.get()
        }, 
        error => alert('Hubo un Error' + error)
      )
    },
    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    save () {
      if(this.editedItem.img == undefined){
        this.editedItem.img = 'https://via.placeholder.com/150/eb6513/000.png'
      }
      var candidato = {
        nombre: this.editedItem.nombre,
        apellido: this.editedItem.apellido,
        desc: this.editedItem.desc,
        img: this.editedItem.img
      }
      if(this.editedIndex === -1){
      this.$http.post(this.$cfg.base + '/candidato', candidato).then(
        () => {
          this.close()
          this.get()
        }, 
        error => alert('Hubo un Error' + error)
      )
      }else{
        this.$http.put(`${this.$cfg.base}/candidato/${this.editedItem.id}`, candidato).then(
          () =>{
            this.close()
            this.get()
          },
          error => alert('Hubo un Error' + error)
        )
      }
    },
    uploadFile: function () {
      let file = this.$refs.fileInput.files[0];

      if (!file) {
        alert("no ha seleccionado ningún archivo");
        return;
      }

      let formData = new FormData();

      formData.append('file', file);

      return this.$http.post(
        `${this.$cfg.base}/upload`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
      )
      .then(response => {
        this.editedItem.img = response.body.fileUrl
        alert("Archivo enviado exitosamente");
      })
      .catch(error => {
        alert("Envio de Archivo fallido: ", error);
      });
    }
  }
}
</script>

<style scoped>
  .title-1{
    margin: 0px 10px 0px 10px;
    padding: 10px 0px 10px 0px;
  }
  .cands{
    padding: 25px 0px 25px 0px;
  }
  .cont{
    max-width: 600px;
  }
</style>