<template>
  <div class="ma5">
    <v-toolbar flat color="white">
      <v-toolbar-title>Socios</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      </v-toolbar>
      <v-spacer></v-spacer>
      <v-dialog v-model="edit" max-width="600px">
        <template v-slot:activator="{ on }">
          <v-btn color="orange darken-2" dark class="mb-2" v-on="on">Nuevo Socio</v-btn>
        </template>
        <v-form>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer></v-spacer>
            <v-btn
            v-show="this.editedIndex != -1"
            color="secondary"
            dark class="mb-2"
            @click="newPass(editedItem)">Nueva Contraseña</v-btn>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field v-model="editedItem.nombre" label="Representante" type="text"></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field v-model="editedItem.empresa" label="Empresa" type="text"></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field :rules="[rules.validateRut]" v-model="editedItem.rut" :key-up="formatedRut" label="RUT" type="text"></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field :rules="[rules.email]" v-model="editedItem.email" label="Email" type="email"></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-select :items="roles" v-model="editedItem.role" label="Rol" type="text"></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-switch v-if="this.editedIndex != -1" v-model="editedItem.votoFisico" label="Voto Fisico"></v-switch>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="orange darken-2" flat @click="close">Cancelar</v-btn>
            <v-btn v-if="this.editedIndex === -1" color="orange darken-2" dark @click="nuevo">Guardar</v-btn>
            <v-btn v-if="this.editedIndex != -1" color="orange darken-2" dark @click="edite">Editar</v-btn>
          </v-card-actions>
        </v-card>
        </v-form>
      </v-dialog>
    
    
    <br>
    <v-card v-if="users">
      <v-card-text>
        <v-list two-line>
          <template>
            <div v-for="(user, index) in users" :key="user.id">
              <v-list-tile>
                <v-list-tile-avatar class="ma-2" size="100">
                  <v-icon color="green" v-if="user.hasVoted">check_circle</v-icon>
                  <v-icon color="red" v-else>cancel</v-icon>
                  Voto Electr&oacute;nico
                </v-list-tile-avatar>
                <v-list-tile-avatar class="ma-2" size="100">
                  <v-icon color="green" v-if="user.votoFisico">check_circle</v-icon>
                  <v-icon color="red" v-else>cancel</v-icon>
                  Voto Físico
                </v-list-tile-avatar>
                <v-spacer></v-spacer>
                <v-list-tile-content>
                  <v-list-tile-title>{{user.nombre}}</v-list-tile-title>
                  <v-list-tile-sub-title>{{user.empresa}}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-content>
                  <v-list-tile-title>{{user.rut}}</v-list-tile-title>
                  <v-list-tile-sub-title>{{user.role}}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action @click="editItem(user)">
                  <v-icon>edit</v-icon>
                </v-list-tile-action>
                <v-list-tile-action :style="{ visibility: (user.role == 'Administrador' ? 'hidden' : 'visible' ) }" @click="deleteThis(user.id)">
                  <v-icon>delete</v-icon>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider v-if="index != users.length - 1"/>
            </div>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'users',
    data() {
      return {
        size: 12,
        roles: ['Votante','Administrador'],
        editedIndex: -1,
        editedItem: {
          nombre: undefined,
          empresa: undefined,
          rut: '',
          email: '',
          role: undefined,
          votoFisico: undefined,
          password: undefined
        },
        defaultItem: {
          nombre: undefined,
          empresa: undefined,
          rut: '',
          email: '',
          role: 'Votante',
          votoFisico: false,
          password: undefined
        },
        users: undefined,
        mostrar: false,
        edit: false,
        rules: {
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
          validateRut: value => { 
            let valor = value.replace(/^0+|[^0-9kK]+/g, '').toUpperCase()
            // Aislar Cuerpo y Dígito Verificador
            let cuerpo = valor.slice(0,-1)
            let dv = valor.slice(-1).toUpperCase()
            // Si no cumple con el mínimo ej. (n.nnn.nnn)
            if(cuerpo.length < 7) { return false }
            if(cuerpo.length > 12) { return false }
            // Calcular Dígito Verificador
            let suma = 0;
            let multiplo = 2;
            
            // Para cada dígito del Cuerpo
            for(let i=1;i<=cuerpo.length;i++) {
              // Obtener su Producto con el Múltiplo Correspondiente
              let index = multiplo * valor.charAt(cuerpo.length - i)
              
              // Sumar al Contador General
              suma = suma + index
              
              // Consolidar Múltiplo dentro del rango [2,7]
              if(multiplo < 7) { multiplo = multiplo + 1 } else { multiplo = 2 }
            }
            
            // Calcular Dígito Verificador en base al Módulo 11
            let dvEsperado = 11 - (suma % 11)
            
            // Casos Especiales (0 y K)
            dv = (dv == 'K')?10:dv
            dv = (dv == 0)?11:dv
            
            // Validar que el Cuerpo coincide con su Dígito Verificador
            if(dvEsperado == dv)  return  true 
              else  return 'RUT Invalido' 
          }
        }
      }
    },
    computed: {
      formatedRut () {
        return this.editedItem.rut
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Socio' : 'Editar Socio'
      }
    },
    watch: {
      formatedRut(val) {
        return this.editedItem.rut = this.formatRut(val)
      },
      edit (val) {
        val || this.close()
      }
    },
    methods: {
      get() {
        let url = this.$cfg.base + '/users'
        this.$http.get(url).then(
          response => {
            if(response.body.length > 0) this.users = response.body
            else this.users = undefined
          }, 
          error => alert(error)
        )
      },
      close () {
        this.edit = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },
      formatRut(item) {
        // Despejar Puntos
        let valor = item.replace(/^0+|[^0-9kK]+/g, '').toUpperCase()
        
        // Aislar Cuerpo y Dígito Verificador
        let cuerpo = valor.slice(0,-1)
        let dv = valor.slice(-1).toUpperCase()
        // Formatear RUN
        item = cuerpo + '-'+ dv
        return item
      },
      nuevo () {
        let user = {
          nombre: this.editedItem.nombre,
          empresa: this.editedItem.empresa,
          rut: this.editedItem.rut,
          email: this.editedItem.email,
          role: this.editedItem.role,
          password: this.generate()
        }
        /*if(this.validateRut(user.rut) == false){
          this.$refs.form[3].setCustomValidity("RUT Inválido");
        }*/
        if(this.editedIndex === -1) {
          this.$http.post(this.$cfg.base + '/user', user).then(
            () => {
              this.close()
              this.get()
            }, error => alert(error)
          )
        } else {
          alert('Algo no paso')
        }
      },
      edite () {
        let user = {
          nombre: this.editedItem.nombre,
          empresa: this.editedItem.empresa,
          rut: this.editedItem.rut,
          email: this.editedItem.email,
          role: this.editedItem.role,
          votoFisico: this.editedItem.votoFisico
        }
        if(this.editedIndex != -1) {
          this.$http.put(`${this.$cfg.base}/user/${this.editedItem.id}`, user).then(
            () =>{
              this.close()
              this.get()
            },
            error => alert(error)
          )
        } else {
          alert('Algo no paso')
        }
      },
      editItem (item) {
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.edit = true
      },
      deleteThis(id){
        let sure = confirm("Esta seguro que desea eliminar el Socio")

        if (sure === true){
          this.eliminar(id)
          alert("Socio Eliminado")
        }else{
          alert("No se elimino el Socio")
        }
      },
      eliminar(id) {
        let url = `${this.$cfg.base}/user/${id}`
        this.$http.delete(url).then(
          () =>{
            this.get()
          }, 
          error => alert(error)
        )
      },
      generate () {
        let CharacterSet = '';
        let password = '';
        /* Character set controla los caracteres que se usaran para generar password aleatorias*/
        CharacterSet += 'abcdefghijklmnopqrstuvwxyz';
        CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        CharacterSet += '0123456789';
        CharacterSet += '![]{}()%&*$#^<>~@|';
              
        for(let i=0; i < this.size; i++) {
          password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
        }
        return password;
      },
      newPass(item){
        let user = {
          rut: item.rut,
          nombre: item.nombre,
          empresa: item.empresa,
          email: item.email,
          role: item.role,
          password: this.generate()
        }
        this.$http.put(`${this.$cfg.base}/newPass/${this.editedItem.id}`, user).then(
          () =>{
            this.close()
          },error => alert(error)
        )
      }
    },
    mounted() {
      this.get()
    }
  }
</script>

<style scoped>
  .title-1{
    margin: 0px 8px 0px 8px;
  }
</style>