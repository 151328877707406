<template>
<div>
	<nav class="bg">
		<img class="logo" src="../assets/CIRPAN-nav.png" alt="CIRPAN">
	</nav>
	<div class="center">
	<h1>La Votacion se encuentra desactivada!</h1>
	<p>Hasta un nuevo periodo.</p>
	<v-btn @click="logout" color="blue">Salir</v-btn>
	</div>
</div>
</template>

<script>
export default{
	name: 'unactive',
	data(){
		return {
			
		}
	},
	methods:{
		logout() {
			localStorage.removeItem('user')
			this.$session.remove('jwt')
			this.$router.push('/')
		}
	},
	created(){
		setTimeout(() => {
			this.logout()
		}, 10000)
	}
}
</script>

<style scoped>
.center{
  text-align: center;
}
</style>

