<template>
  <div class="ma5">
    <v-toolbar flat color="white">
      <v-toolbar-title>Historico</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      </v-toolbar>
      <v-spacer></v-spacer>
      <v-dialog v-model="view" max-width="1000px">
        <v-card>
          <v-card-title>
            <span class="headline">Periodo {{ editedItem.fecha }}</span>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" flat @click="close">Cerrar</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <h4>Total de Votos: {{ editedItem.totalVotos }}</h4>
                  <h4>Total de Empresas que Participaron: {{ editedItem.totalVotados }}</h4>
                  <br>
                </v-flex>
              <template>
                <v-expansion-panel>
                  <v-expansion-panel-content>
                    <template v-slot:header>
                      <div>Candidatos</div>
                    </template>
                    <v-card>
                      <v-layout row wrap>
                        <v-flex class="candidato text-xs-center" v-for="(candidato, index) in editedItem.candidatos" :key="candidato.id" xs4 md4 lg4>
                          <img class="candidato-img" :src="candidato.img" />
                          <div class="text-xs-center">
                            <h2>{{ candidato.nombre}} {{candidato.apellido}}</h2>
                            <h4>Votos: {{candidato.votos}}</h4>
                            <h5>{{ candidato.desc }}</h5>
                          </div>
                          <v-divider v-if="index != editedItem.candidatos.length - 1"/>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-expansion-panel-content>
                  <v-expansion-panel-content>
                    <template v-slot:header>
                      <div>Empresas Participantes</div>
                    </template>
                    <v-card>
                      <v-layout row wrap>
                        <v-flex class="candidato text-xs-center" v-for="(empresa, index) in editedItem.totalVotantes" :key="empresa.id" xs4 md4 lg4>
                          <div class="text-xs-center">
                            <h2>{{ empresa.nombre}}</h2>
                            <h5>{{ empresa.empresa }}</h5>
                          </div>
                          <v-divider v-if="index != editedItem.totalVotantes.length - 1"/>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </template>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    <br>
    <v-card v-if="periodos">
      <v-card-text>
        <v-list two-line>
          <template>
            <div v-for="(periodo, index) in periodos" :key="periodo.id">
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>Periodo {{periodo.fecha}}</v-list-tile-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-icon color="blue" @click="viewThis(periodo)">remove_red_eye</v-icon>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider v-if="index != periodos.length - 1"/>
            </div>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'historico',
    data() {
      return {
        view: false,
        periodos: [],
        editedIndex: -1,
        editedItem: {
          fecha: undefined,
          candidatos: [],
          totalVotos: undefined,
          totalVotantes: []
        },
        defaultItem: {
          fecha: undefined,
          candidatos: [],
          totalVotos: undefined,
          totalVotantes: []
        },
      }
    },
    methods: {
      get() {
        var url = this.$cfg.base + '/periodos'
        this.$http.get(url).then(
          response => {
            if(response.body.length > 0) this.periodos = response.body
            else this.periodos = undefined
          }, 
          error => alert(error)
        )
      },
      close () {
      this.view = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },
      viewThis(item){
        this.editedIndex = this.periodos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.view = true
      },
      deleteP(id){
        this.$http.delete(`${this.$cfg.base}/periodo/${id}`)
      }
    },
    mounted() {
      this.get()
    }
  }
</script>
<style scoped>
.candidato {
  display:block;
  background: none;
  padding: 1em;
}
.candidato-img{
  height: 150px;
}
</style>