<template>
  <nav>
    <v-toolbar flat app>
      <v-toolbar-side-icon @click="drawer = !drawer" class="grey--text"></v-toolbar-side-icon>
      <v-toolbar-title class="text-uppercase grey--text">
        <span>CIRPAN </span>
        <span class="font-weight-light">A.G.</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn flat color="grey" @click="logout">
        <span>Salir</span>
        <v-icon right>exit_to_app</v-icon>
      </v-btn>
    </v-toolbar>

    <v-navigation-drawer app v-model="drawer" class="grey">
      <v-layout column align-center>
        <v-flex class="mt-5">
          <img src="../../assets/CIRPAN-nav.png" alt="" class="logo">
          <!--p class="titulo black--text ma-2">CIRPAN - Circulo de Empresas Panamericana Norte</p-->
        </v-flex>
      </v-layout>
      <v-list class="orange darken-1">
        <v-list-tile v-for="link in links" :key="link.text" router :to="link.route">
          <v-list-tile-action>
            <v-icon class="white--text">{{ link.icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title class="titulo white--text">{{ link.text }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      drawer: true,
      links: [
        { icon: 'people', text: 'Candidatos', route: '/admin/candidatos' },
        { icon: 'person', text: 'Socios', route: '/admin/socios' },
        { icon: 'history', text: 'Historico', route: '/admin/historico' },
        { icon: 'settings', text: 'Configuración', route: '/admin/config' }
      ]
    }
  },
  methods: {
      logout() {
        localStorage.removeItem('user');
        this.$router.replace('/')
      }
    }
}
</script>

<style>
.logo{
max-width: 300px;  
}
</style>

