import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Votar from './views/Votar.vue'
import Resultado from './views/Resultado.vue'
import Fin from './views/Fin.vue'
import Admin from './views/Admin.vue'
import Candidatos from './components/admin/Candidatos.vue'
import Socios from './components/admin/Socios.vue'
import Conf from './components/admin/Conf.vue'
import Unactive from './views/Unactive.vue'
import Historico from './components/admin/Historico.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/home',
      redirect: '/'
    },
    {
      path: '/votar',
      name: 'votar',
      component: Votar,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/unactive',
      name: 'unactive',
      component: Unactive,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/resultado',
      name: 'resultado',
      component: Resultado,
      meta: { requiresAuth: true}
    },
    {
      path: '/fin',
      name: 'fin',
      component: Fin,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'candidatos',
          component: Candidatos
        },
        {
          path: 'socios',
          component: Socios
        },
        {
          path: 'historico',
          component: Historico
        },
        {
          path: 'config',
          component: Conf
        }
      ]
    }    
  ]
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/', '/home']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')

  if (authRequired && !loggedIn) {
    return next('/')
  }
  next()
})

export default router;