<template>
  <div>
    <nav class="bg">
    <img class="logo" src="../assets/CIRPAN-nav.png" alt="CIRPAN">
    </nav>
    <v-container v-if="config.active">
      <v-layout justify-center align-center row wrap>
      <v-flex xs12 sm6 class="center">
      <h1>ELECCIONES CIRPAN</h1><br>
      <p>{{config.textHome}}</p>
      <v-alert :value="alert"
        type="error"
        transition="scale-transition"
        dismissible
      >
        El RUT o la Contraseña son incorrectos.
      </v-alert>
      <form class="ppal" method="POST" @submit.prevent="login">
        <v-card class="" elevation="10">
          <v-card-title primary-title>
            <div class="headline">Iniciar Sesion</div>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field v-model="rut" name="rut" ref="rut" placeholder="Ingrese RUT" required></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="password" placeholder="Ingresa tu contraseña." type="password" required></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" type="submit">Ingresar</v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-flex>
      </v-layout>
    </v-container>
    <v-container v-else-if="config.active==false">
      <v-layout justify-center align-center row wrap>
        <v-flex xs12 class="center outServ">
          <h1>El Sistema esta Fuera de Servicio</h1>
          <v-btn color="blue darken-1" @click="adminAccess = true">Acceso Admin</v-btn>
          <v-dialog v-model="adminAccess" max-width="400px">
            <v-card>
              <v-layout>
                <v-flex class="center">
                  <form class="admin" method="POST" @submit.prevent="login">
                      <v-card-title primary-title>
                        <div class="headline">Iniciar Sesion</div>
                      </v-card-title>
                      <v-card-text>
                      <v-container grid-list-md>
                          <v-layout wrap>
                            <v-flex xs12>
                              <v-text-field v-model="rut" name="rut" ref="rut" placeholder="Ingrese RUT" required></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                              <v-text-field v-model="password" placeholder="Ingresa tu contraseña." type="password" required></v-text-field>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" type="submit">Ingresar</v-btn>
                      </v-card-actions>
                    </form>
                  </v-flex>
                </v-layout>
            </v-card>
          </v-dialog>
        </v-flex>
      </v-layout>
    </v-container>
    <footer></footer>
  </div>
</template>

<script>
//  @click="goto('votar')"
export default {
  name: 'home',
  data:() => ({
    config: {},
    rut: undefined,
    password: undefined,
    user: {},
    alert: false,
    adminAccess: false
  }),
  watch:{
    rut (val) {
      this.rut = this.formatRut(val)
    }
  },
  methods: {
    validateRut(rut){
      // Aislar Cuerpo y Dígito Verificador
      let cuerpo = rut.slice(0,-1)
      let dv = rut.slice(-1).toUpperCase()
      // Si no cumple con el mínimo ej. (n.nnn.nnn)
      if(cuerpo.length < 7) { this.$refs.rut.setCustomValidity("RUT Incompleto"); return false}
      // Calcular Dígito Verificador
      let suma = 0;
      let multiplo = 2;
      
      // Para cada dígito del Cuerpo
      for(let i=1;i<=cuerpo.length;i++) {
        // Obtener su Producto con el Múltiplo Correspondiente
        let index = multiplo * rut.charAt(cuerpo.length - i)
        
        // Sumar al Contador General
        suma = suma + index
        
        // Consolidar Múltiplo dentro del rango [2,7]
        if(multiplo < 7) { multiplo = multiplo + 1 } else { multiplo = 2 }
      }
      
      // Calcular Dígito Verificador en base al Módulo 11
      let dvEsperado = 11 - (suma % 11)
      
      // Casos Especiales (0 y K)
      dv = (dv == 'K')?10:dv
      dv = (dv == 0)?11:dv
      
      // Validar que el Cuerpo coincide con su Dígito Verificador
      if(dvEsperado != dv) { this.$refs.rut.setCustomValidity("RUT Inválido"); return false; }
      
      // Si todo sale bien, eliminar errores (decretar que es válido)
      this.$refs.rut.setCustomValidity("")
    },
    formatRut(item) {
      // Despejar Puntos
      let valor = item.replace(/^0+|[^0-9kK]+/g, '').toUpperCase()
      
      // Aislar Cuerpo y Dígito Verificador
      let cuerpo = valor.slice(0,-1)
      let dv = valor.slice(-1).toUpperCase()
      // Formatear RUN
      item = cuerpo + '-'+ dv
      return item
    },
    logout() {
      localStorage.removeItem('user')
      this.$session.clear()
      this.$session.destroy()
      this.$router.push('/')
    },
    login() { 
      /*if(){
        setTimeout(() => {
        this.modal=true
        this.logout()
        }, 150000)  
      }*/
      let user = {
        rut: this.rut,
        password: this.password
      }
      this.$http.post(this.$cfg.base + '/login', user).then(
        response => {
          this.adminAccess = false
          this.user = response.body
          if (response.status === 200) {
            this.$session.start()
            this.$session.set('jwt', response.body.token)
            // Vue.http.headers.common['Authorization'] = 'Bearer ' + response.body.token
          }
          if (response.body.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(response.body));
          }
          //Chequeo de rol
          if(response.body.role === 'Administrador') {
            this.$router.replace('admin')
          } else if(response.body.role === 'Votante' && this.config.active == false){
            this.$router.push('/unactive')
          }else{
            this.hasVoted()
            this.$router.push({ name: 'votar', params: { id: response.body._id } })
          }
          //else this.$router.replace('/votar')
        }, () => this.alert = true
      ).catch(error => alert('Hubo un Error' + error));
    },
    hasVoted() {
      this.$http.get(`${this.$cfg.base}/user/${this.user._id}`).then(
        response => {
          let user = response.body
          if (user.hasVoted == true) this.$router.push('fin')
          else if(user.votoFisico == true) this.$router.push('fin')
        }, error => alert('Hubo un Error' + error)
      ) 
    },
    getConfig() {
      this.$http.get(this.$cfg.base + '/cfg').then(response => {
        this.config = response.body[0]
      }, error => alert('Hubo un Error ' + error))
    }
  },
  beforeMount() {
    this.getConfig()
  }
}
</script>

<style scoped>
.logo{
  margin:15px 0px 0px 15px;
  padding:10px 0px 0px 10px;
}
footer{
  height:240px;
  background-color: #EB6513;
  -webkit-clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.center{
    text-align: center;
}
.container{
  padding: 0px;
}
.outServ{
  padding: 100px;
}
form.ppal{
  margin: 0px 50px 0px 50px;
  padding: 0px 50px 0px 50px;
}
form.admin{
  padding: 10px 30px 10px 30px;
}
input[type=text]{
  border: 1px solid black;
  margin: 5px;
  padding: 8px;
}
input[type=password]{
  border: 1px solid black;
  margin: 5px;
  padding: 8px;
}
input[type=submit]{
  margin: 5px;
  font-size: 14px;
  padding:10px 18px;
  border-radius: 5px;
  color: black;
  background-color: royalblue;
}
</style>