<template>
  <div>
    <v-container v-if="candidatos && config" fluid grid-list-sm class="main text-xs-center">
      <img src="../assets/CIRPAN-nav.png" class="logo">
      <h1>{{config.titulo1}}</h1>
      <p>{{config.texto1}}</p>
      <br>
      <v-layout justify-space-around align-center column wrap>
        <v-flex xs4>
          <div class="candidato text-xs-center">
            <table>
              <tr>
                <th>Nombre</th>
                <th>Votos</th>
              </tr>
              <tr v-for="candidato in candidatos" :key="candidato.id">
                <td>Sr. {{candidato.nombre}} {{candidato.apellido}}</td>
                <td>{{candidato.votos}}</td>
              </tr>              
            </table>
          </div>
        </v-flex>
      </v-layout>
      <p>Votos Emitidos Electronicamente: {{totalVotos}}</p> 
      <br>
      <h3>Total de Empresas que han votado en urna online: {{ totalElectronico }}</h3> 
      <br>
      <h3>Total de Empresas que han votado en urna fisica: {{totalFisico}}</h3>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'resultado',
    data() {
      return {
        config: undefined,
        candidatos: [],
        votantes: [],
        totalElectronico: undefined,
        totalFisico: undefined,
        totalVotantes: [],
        totalVotos: undefined
      }
    },
    methods: {
      get() {
        this.$http.get(this.$cfg.base + '/candidatos').then(response => {
          this.candidatos = response.body.sort((a, b) => b.votos - a.votos)
        }, error => alert('Hubo un Error' + error))      
      },
      getConfig() {
        this.$http.get(this.$cfg.base + '/cfg').then(response => {
          this.config = response.body[0]
          this.isAdmin()
          this.get()
          this.totalEmpresas()
          this.countHasvoted()
          this.totalVotiwis()
        }
        , error => alert('Hubo un Error' + error))
      },
      countHasvoted() {
        // contar voto electronico y fisico por empresa
        this.$http.get(this.$cfg.base + '/users').then(response => {
          this.votantes = response.body
          let totalElect = 0
          let totalFisico = 0
          this.votantes.forEach(votante => {
            if(votante.hasVoted) totalElect++
            if(votante.votoFisico) totalFisico++
          })
          this.totalElectronico = totalElect
          this.totalFisico = totalFisico

        }, error => alert('Hubo un Error' + error))  
      },
      isAdmin() {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.role != 'Administrador') {
          this.$router.push('unactive')
        }
      },
      totalVotiwis() {
        this.$http.get(this.$cfg.base + '/candidatos').then(response => {
          const candidatos = response.body
          // contar votos electronicos por candidato
          let votos = 0
          candidatos.forEach(candidato => {
            votos += candidato.votos
          })
          this.totalVotos = votos
        }, error => alert('Hubo un Error' + error))
      },
      historical(){
        let f = new Date()
        let y = f.getFullYear()
        let c = y+2
        let periodo = {
          fecha: y + '-' +  c,
          candidatos: this.candidatos,
          totalVotos: this.totalVotos,
          totalElectronico: this.totalElectronico,
          totalFisico: this.totalFisico,
          totalVotados: this.totalElectronico+this.totalFisico,
          totalVotantes: this.totalVotantes
        }
        this.$http.post(this.$cfg.base + '/periodo', periodo).then(()=>{
          this.reset()
        })
      },
      reset(){
        let body = {
          candidatos: this.candidatos,
          users: this.votantes
        }
        this.$http.post(this.$cfg.base + '/reset', body)
      },
      totalEmpresas(){
        this.$http.get(this.$cfg.base + '/users').then(response => {
          let votantes = response.body
          
          votantes.forEach(votante => {
            if(votante.hasVoted == true) this.totalVotantes.push(votante)
            if(votante.votoFisico == true) this.totalVotantes.push(votante)
          })
        }, error => alert('Hubo un Error' + error))
      }
    },
    mounted(){
      this.getConfig()
      setTimeout(() => {
        this.historical()
      }, 5000)
    }
  }
</script>

<style scoped>
  img {
    height: 150px;
  }
  .candidato {
    display:block;
    background: none;
    padding: 1em;
  }
</style>