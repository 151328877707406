<template>
  <v-app id="app">
    <v-content>
      <router-view/>
    </v-content>
  </v-app>  
</template>

<script>
  export default {
    beforeCreate(){
      document.title = 'Sistema de Votacion Electronica'
    }
  }
</script>

<style>
  .bg {
    background-color: #828080;  
    -webkit-clip-path: polygon(0 0, 0% 100%, 100% 0);
    clip-path: polygon(0 0, 0% 100%, 100% 0);
    padding: 0px;
    height: 150px;
  }
  body {  
    background: rgba(201, 197, 197, 0.35);
  }
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
  .titulo{
    font-family: 'Montserrat', sans-serif;  
  }
</style>